.imageContainer {
  width: 100vw; /* Ancho completo de la pantalla */
  position: relative;
  height: 30vh; /* Reducir la altura a la mitad de la altura de la ventana */
  margin: 0 auto;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ajustar la imagen al contenedor sin recortar */
}

/* Asegúrate de que el contenedor principal sea scrollable */
.container {
  padding: 0% 1% 0 1%;
  margin-top: 0;
  position: relative;
  top: 0;
  
  max-height: 80vh; /* Limita la altura de la tarjeta para que no ocupe toda la pantalla */
  overflow-y: auto; /* Hacer que el contenido sea desplazable */
}


.card {
  margin-top: 0px;
  border-radius: 8px;
  
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
}

.content {
  padding: 15px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #002055;
  margin-top: 10px;
  margin-bottom: -4px;
  text-align: left;
}

.messageIcon {
  margin-top: 10px;
  width: 24px; /* Ajusta el tamaño del ícono según sea necesario */
  height: 24px;
  margin-left: 8px; /* Añade un pequeño espacio entre el título y el ícono */
}

.location {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px; /* Ajusta el tamaño según sea necesario */
  margin-bottom: 20px;
  color: #555;
}

.descriptionTitle,
.documentsTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 15px; /* Ajusta el tamaño según sea necesario */
  color: #002055;
  margin-bottom: 2px;
}

.description {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #555;
  margin-top: 0px;
  margin-bottom: 16px;
}

.docButton {
  display: flex;
  justify-content: flex-start;
  text-transform: none;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  color: #002055;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.docButton:hover {
  background-color: #e0e0e0;
}

.file-input-wrapper-apr {
  display: flex;
  align-items: center;  
  justify-content: space-between; 
  width: 90%;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 0.850rem;
  background-color: #f9f9f900;
  cursor: pointer;
}

.file-input-wrapper-apr:hover {
  border-color: #7163f1;
  background-color: #f0f0f0;
}

.file-uploadr {
  display: none; 
}

.file-upload-labelr {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  color: #333;
  cursor: pointer;
}

.label-apr{
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #888888;
}

.upload-text-apr {
  flex-grow: 1; 
  text-align: left; 
  padding-left: 12%; 
  color: #002055;
  font-weight: 600;
}

.upload-iconr {
  width: 10%;
  height: auto;
  padding-right: 10px;  
}

.upload-icon-rr {
  width: 10%;
  height: auto;
}

.uploadButtonContainer {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  height: 40px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.uploadButtonText {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #002055;
  margin: 0 auto; /* Centra el texto horizontalmente */
}

.uploadIcon, .deleteIcon {
  font-size: 24px;
  color: #002055;
}

.uploadIcon:hover, .deleteIcon:hover {
  color: #303f9f;
  cursor: pointer;
}




.quotationButton {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #D0CCF1;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quotationButton:hover {
  background-color: #756df4;
}


.submitButton {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #1a1a24;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #2a2a3c; /* Color de fondo al pasar el cursor */
}


.slick-dots {
  bottom: 10px;
}

.slick-dots li {
  margin: 0 -4px;
}

.slick-dots li button:before {
  font-size: 8px;
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  color: #3f51b5;
}
