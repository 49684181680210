/* WelcomeView.css */
.wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 100vh;           /* Full viewport height */
  background-color: #ffffff; /* Optional: background color for visibility */
  padding-top: 8%;
}

.welcome-container {
  display: flex;
  flex-direction: column; /* Place items in a column */
  align-items: center;    /* Center items horizontally */
  text-align: center;     /* Center text */
  gap: 20px;              /* Space between items */
  padding: 20px;          /* Add padding to container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  max-width: 100%;        /* Ensure the container does not exceed the viewport width */
}


.welcome-image {
  width: 60%; /* Adjust width as needed */
  max-width: 600px; /* Adjust max-width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below the image */
  padding-bottom: 16%;
    margin-top: 40%; /* Space below the image */
  padding-bottom: 16%;
}

.phase-image {
  width: 16%;
  max-width: 600px; /* Adjust max-width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below the image */
  padding-bottom: 16%;
}

.welcome-title {
  font-family: 'Poppins', sans-serif; /* Apply the Poppins font */
  font-size: 2.25rem; /* Font size */
  font-weight: 400; /* Font weight */
  line-height: 45px; /* Line height */
  text-align: center; /* Center align text */
  color: #002055; /* Text color */
  margin: 0; /* Remove default margin */
  padding-top: 8%;
  padding-bottom: 16%;
}

.welcome-subtitle {
  font-size: 0.85rem; /* Adjust font size */
  font-family: 'Poppins', sans-serif; 
  color: #b1b1b1; /* Text color */
  margin: 0; /* Remove default margin */
  word-wrap: break-word; /* Break long words to wrap within the container */
  hyphens: auto; /* Optional: add hyphenation if needed for better wrapping */
}

.welcome-button {
  background: linear-gradient(135.96deg, #8B78FF 0%, #8B78FF 60%, #3580FF 100%);
  color: white;
  border: none;
  padding: 10px 0;
  font-size: 16px;
  border-radius: 18px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  height: 56px;
  max-width: 400px;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Sombra añadida */
}

.welcome-button span {
  font-size: 99%; /* Font size of the button text */
  font-weight: 600; /* Font weight */
  line-height: 24px; /* Line height */
  letter-spacing: 0.70px; /* Letter spacing */
}



.welcome-button:hover {
  background: linear-gradient(135.96deg, #7a6eff 0%, #2f6fd2 101.74%); /* Slightly different gradient for hover */
}


@media (min-width: 768px) {
  .welcome-container {
    padding: 40px;
    /* Other styles for larger screens */
  }
}
