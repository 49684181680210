html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Previene el scroll en el contenedor principal */
}

.body-wrapper {
  width: 100%;
}

.padding-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 16%;
  margin-left: 0;
}

.welcome-image {
  width: 100%;
  height: auto;
  display: block;
  padding: 0;
  margin-top: 0;
  padding-bottom: 8%;
}

.text-left {
  text-align: left;
  width: 100%;
  margin: 0;
}

.text-left.title {
  font-family: Poppins, sans-serif;
  font-size: 100%;
  color: #756EF3;
}

.text-quotation {
  font-family: Poppins, sans-serif;
  font-size: 200%;
  text-align: justify;
}

.highlight-blue {
  color: #756EF3;
  font-weight: bold;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 8%;
}

.image-and-skip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 32%;
}

.phase-image {
  width: 40%;
  height: auto;
  margin-bottom: 16%;
}

.skip-text {
  color: #000000;
  font-family: Poppins, sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: 0;
  padding-top: 16%;
  padding-bottom: 32%;
}

.curved-button-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
}
