.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #cccccc7d;
  border-radius: 8px;
  background-color: #fff;
  padding: 0 4%;
  height: 50px;
  max-width: 100%;
  box-sizing: border-box;
}

.search-bar svg {
  width: 20px;
  height: 20px;
  color: #7a8c99;
  flex-shrink: 0;
}

.all-projects-container {
  flex-grow: 1;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 7rem;
  overflow: auto;
}

.search-bar input {
  flex: 1;
  border: none;
  padding: 8px;
  font-size: 16px;
  color: #7a8c99;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
}

.search-bar input::placeholder {
  color: #a0a0a0;
}

.search-bar input:focus {
  outline: none;
}

.go-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  /* Mantén el color de fondo blanco */
  border-radius: 8px;
  padding-left: 8px;
  /* Añade un poco de espacio antes de la imagen si es necesario */
}

.go-image {
  height: 100%;
  width: auto;
  border-radius: 8px;
}


.go-text {
  color: #ffffff;
  /* Color más claro para el texto */
  font-size: 14px;
  /* Tamaño de la fuente ajustado */
  font-weight: 600;
  position: absolute;
  font-family: "Poppins", sans-serif;
}

.project-selector {
  display: flex;
  width: 100%;
  height: 6%;
  margin: 5% 0;
  position: relative;
  border-radius: 2rem;
  /* Rounded corners */
  background-color: #f3f3f3;
  /* Default gray background */
  overflow: hidden;
}

.project-button {
  flex: 1;
  padding: 2.5% 0;
  font-size: 0.875rem;
  /* 14px */
  border: none;
  background-color: transparent;
  color: #7a7a7a;
  cursor: pointer;
  z-index: 1;
  text-align: center;
  transition: color 0.3s ease;
  /* Smooth transition for text color */
}

.project-button.active {
  color: white;
}

.switch-background-project {
  position: absolute;
  top: 0;
  width: 50%;
  /* Takes half of the switch's width */
  height: 100%;
  background-color: #7163f1;
  /* Blue background */
  border-radius: 2rem;
  transition: left 0.3s ease;
  /* Smooth transition for sliding */
  z-index: 0;
}

.switch-background-project.participated {
  left: 0;
  /* Position background on the left */
}

.switch-background-project.notjoined {
  left: 50%;
  /* Position background on the right */
}
