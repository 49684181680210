.chat-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 400px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 100vh; /* Altura del contenedor del chat */
  overflow: hidden; /* Oculta el desbordamiento vertical inicial */
  position: relative;
}

.Messages-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Coloca los mensajes al final */
  flex-grow: 1; /* Permite que el contenedor se expanda para ocupar el espacio disponible */
  overflow-y: auto; /* Permite desplazamiento vertical cuando hay muchos mensajes */
  padding-bottom: 8px; /* Espacio debajo del último mensaje */
}

.message-container {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.user-message {
  display: flex;
  justify-content: flex-end; /* Alinea el bubble del usuario a la derecha */
}

.user-bubble {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  margin-left: auto; /* Empuja el bubble a la derecha */
  padding: 12px;
  border-radius: 20px;
  max-width: 70%;
  line-height: 1.5;
  font-size: 14px;
}

.hashim-message {
  display: flex;
  justify-content: flex-start; /* Alinea el bubble de Hashim a la izquierda */
}

.hashim-bubble {
  background-color: #e9e9eb;
  color: #000;
  align-self: flex-start;
  padding: 12px;
  border-radius: 20px;
  max-width: 70%;
  line-height: 1.5;
  font-size: 14px;
}

.message-time {
  font-size: 12px;
  color: #888;
  margin-bottom: 4px;
}

.message-image {
  max-width: 100%;
  border-radius: 8px;
}

.chat-input-container {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
  background-color: #f9f9f9;
  justify-content: space-between; /* To space out the elements evenly */
  margin-bottom: 2%;
}

.input-with-camera {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0.64; /* Reduce el ancho del contenedor del input y la cámara, el valor original era 1 (100%) */
  margin-left: 8px; /* Añade margen a la izquierda para separarlo del icono "MoreAttachments" */
  margin-right: 8px; /* Añade margen a la derecha para separarlo del icono "SendMessage" */
}

.input-with-camera input {
  flex: 1;
  padding: 12px 40px 12px 12px; /* Asegúrate de que el padding sea adecuado para el contenido */
  border: 1px solid #ccc;
  border-radius: 32px;
  font-size: 100%;
  background-color: #f5f5f5;
  transition: border-color 0.3s ease;
}


.input-with-camera input:focus {
  border-color: #756EF3; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.input-with-camera .camera-button {
  position: absolute;
  right: 8px;
  background-color: transparent; /* Match input background to make it blend */
  border: none;
  cursor: pointer;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-with-camera .camera-icon {
  font-size: 18px; /* Adjust the size of the camera icon */
  color: #888;
}

.chat-input-container input::placeholder {
  color: #888; /* Light gray placeholder text */
}

.send-button {
  background-color: #333; /* Dark color for the send button */
  color: white;
  padding: 0;
  border: none;
  border-radius: 50%; /* Makes the button circular */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.attachment-button {
  background-color: #f5f5f5; /* Light gray background for the attachment button */
  padding: 0;
  border: none; /* Remove the border */
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  width: 8%;
  height: auto;
}


.MoreAttachment-icon {
  width: 100%; /* Adjust width to desired size */
  height: 100%; /* Adjust height to desired size */
  object-fit: contain; /* Ensures the image scales without distortion */
}



.attachment-icon, .camera-icon {
  color: #888; /* Light gray icon color */
  font-size: 16px;
}

.camera-button {
  background-color: transparent; /* Light gray background for the camera button */
  padding-right: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  width: 10%;
  height: auto;
}

.InputImage-icon {
  width: 100%; /* Adjust width to desired size */
  height: 100%; /* Adjust height to desired size */
  object-fit: contain; /* Ensures the image scales without distortion */
}

.SendMessage-icon {
  width: 50%; /* Adjust width to desired size */
  height: 50%; /* Adjust height to desired size */
  object-fit: contain; /* Ensures the image scales without distortion */
}
