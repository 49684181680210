html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 5%;
  font-family: 'Poppins', sans-serif;
  color: #3a3a3a;
  background-image: url("../../../../assets/images/Auth/Background.png");
  background-size: contain;
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}


header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: 8%;
  padding-bottom: 14%;
}

.back-button {
  position: absolute;
  left: 1%; /* Adjusted to be a percentage from the left */
  background: none;
  border: 0.15rem solid #E9F1FF; /* 2px converted to rem */
  border-radius: 50%;
  width: 10vw; /* Width based on viewport width */
  height: 10vw; /* Height based on viewport width */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 80%; /* Font size in percentage relative to parent */
}

h2 {
  margin: 0;
  font-size: 1.16rem;  /* Approx. 16px in rem */
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1.125rem;  /* 18px converted to rem */
  color: #002055;
  text-align: left;
}

.Welcome-backh {
  font-size: 1.625rem; /* 26px converted to rem */
  font-weight: bold;
  margin: 5% 0 2.5%; /* Converted to percentages */
  text-align: left !important; /* Aligns text to the left */
  width: 100%; /* Ensures it takes full width if needed */
  padding-top: 8%;
}


p {
  margin: 1.25% 0; /* Converted to percentage */
  color: #868D95;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}

.role-selector-login {
  display: flex;
  width: 100%;
  margin: 2rem 0;
  position: relative;
  border-radius: 2rem; /* Rounded corners */
  background-color: #f3f3f3; /* Default gray background */
  overflow: hidden;
}

.role-button {
  flex: 1;
  padding: 2.5% 0;
  font-size: 0.875rem; /* 14px */
  border: none;
  background-color: transparent;
  color: #7a7a7a;
  cursor: pointer;
  z-index: 1;
  text-align: center;
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.role-button.active {
  color: white;
}

.switch-background {
  position: absolute;
  top: 0;
  width: 50%; /* Takes half of the switch's width */
  height: 100%;
  background-color: #7163f1; /* Blue background */
  border-radius: 2rem;
  transition: left 0.3s ease; /* Smooth transition for sliding */
  z-index: 0;
}

.switch-background.manager {
  left: 0; /* Position background on the left */
}

.switch-background.contractor {
  left: 50%; /* Position background on the right */
}





.input-field {
  width: 90%; /* Ajusta el ancho para que no exceda el contenedor */
  padding: 3.5%; /* Incrementa el padding para hacer los campos más largos */
  margin: 1.5% auto; /* Centra los campos horizontalmente */
  height: 24px; /* Aumenta el alto del campo */
  border-radius: 0.850rem; /* Mantiene el borde redondeado */
  border: 0.15rem solid #e0e0e0; /* Mantiene el borde */
  font-size: 0.875rem; /* Mantiene el tamaño de fuente */
  font-family: 'Poppins', sans-serif; /* Aplica la fuente 'Poppins' */
  display: block; /* Asegura que cada campo esté en una nueva línea */
}

.input-field:focus {
  border-color: #7163f1; /* Color azul para el borde en foco */
  outline: none; /* Elimina el borde de enfoque predeterminado del navegador */
}

.input-field::placeholder {
  color: #b0b0b0; /* Color gris claro para el texto del placeholder */
  opacity: 1; /* Asegura que el color sea completamente opaco */
  font-family: 'Poppins', sans-serif; /* Asegura que el font-family sea el mismo */
}

.forgot-password {
  align-self: flex-end;
  color: #7163f1;
  margin-top: 2.5%; /* Converted to percentage */
  margin-bottom: 8%; /* Converted to percentage */
  font-size: 0.75rem; /* 12px converted to rem */
  text-decoration: none;
}

.sign-in-button {
  width: 100%;
  padding: 3.75%; /* 15px converted to percentage */
  background-color: #7163f1;
  border: none;
  border-radius: 1rem; /* 30px converted to rem */
  color: white;
  font-size: 1rem; /* 16px converted to rem */
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.continue-with {
  text-align: center;
  margin: 16%;
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 32%; /* Converted to percentage */
  margin-top: 32%;
}

.apple-login,
.google-login {
  width: 50%; /* Define un tamaño fijo para hacer el botón cuadrado */
  height: auto; /* Hace que la altura sea igual al ancho */
  padding: 10%; /* Define un padding intermedio */
  background-color: #ffffff;
  border: 1px solid #dfdfdf; /* Añade un borde azul */
  border-radius: 0.625rem; /* Mantiene los bordes ligeramente redondeados */
  font-size: 1.8rem; /* Tamaño de fuente para el icono */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.sign-up-text {
  font-size: 0.875rem; /* 14px converted to rem */
  color: #7a7a7a;
  
}

.sign-up-text a{
    text-decoration: none; /* Elimina la línea subrayada */
    font-weight: 600;
}
