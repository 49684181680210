.root {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fd5c63;
  color: #fff;
  height: 8% !important; /* Increase the height */
}

.button {
  color: #fff;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.roundButton {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #726ef7;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Add shadow here */
}

.icon {
  font-size: 220%; /* Adjust icon size here */
}

.image {
  width: 46% !important;
  height: auto;
}
